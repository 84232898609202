
































































































import { Api, DictionaryInfo, PagedResults, LetterTileToolTrackedWord, currentDictionaryObjectVersion } from '@/edshed-common/api'
import EditWordDataModal from '@/edshed-common/components/dictionary/EditWordDataModal.vue'

import { Howl } from 'howler'
import { isEqual, cloneDeep, debounce } from 'lodash'
import { Vue, Component } from 'vue-property-decorator'

import { LocaleData, DictionaryLanguage, getLanguageName, getLocaleEmojiFlag } from '@/edshed-common/i18n'
import axios from 'axios'

@Component({ components: { EditWordDataModal } })
export default class LetterTilesWords extends Vue {
  private wordsData: PagedResults<LetterTileToolTrackedWord> = { items: [], total: 0 }

  private editingData: DictionaryInfo | null = null

  private page: number = 1

  private searchLocale: DictionaryLanguage | 'all' = 'en_GB'

  debounce = debounce

  cloneDeep = cloneDeep

  isEqual = isEqual

  private DictionaryLanguage = DictionaryLanguage

  trackingReasons (trackedWord: LetterTileToolTrackedWord) {
    if (trackedWord.id === null) {
      return 'Not in dictionary'
    }

    const noPhonics = !trackedWord.has_phonics
    const noDefinitions = !trackedWord.has_definitions

    if (noPhonics && noDefinitions) {
      return 'No phonics or definitions data'
    } else if (noPhonics) {
      return 'No phonics data'
    } else if (noDefinitions) {
      return 'No definitions data'
    } else {
      return ''
    }
  }

  editOrAddWord (trackedWord: LetterTileToolTrackedWord) {
    if (trackedWord.id === null) {
      this.addWord(trackedWord)
    } else {
      this.editWord(trackedWord.id)
    }
  }

  async editWord (id: number) {
    this.editingData = await Api.getDictionaryWord(id)
  }

  addWord (trackedWord: LetterTileToolTrackedWord) {
    const { word, locale } = trackedWord

    this.editingData = {
      id: 0,
      word,
      word_class: null,
      parent_word: null,
      user_id: 1,
      locale,
      errors: [],
      definitions: [],
      difficulty_index: 0,
      morphemes: [],
      syllables: [],
      sentences: [],
      phonics: [],
      synonyms: [],
      antonyms: [],
      image: null,
      flags: [],
      approved: false,
      hidden: false,
      as_in: null,
      elements: [],
      ipa_definition: null,
      variant_type: null,
      variant_phonics: null,
      variant_audio: null,
      audio_versions: {},
      difficulty: {
        calculated_from_scheme: {}
      },
      is_valid: false,
      object_version: currentDictionaryObjectVersion
    }
  }

  closeDictionaryModal () {
    this.editingData = null
    this.getDictionaryData()
  }

  async getDictionaryData () {
    const locale: DictionaryLanguage | undefined = this.searchLocale !== 'all' ? this.searchLocale : undefined
    this.wordsData = await Api.getLetterTileToolWords({ skip: (this.page - 1) * this.pageSize, take: this.pageSize }, locale)
  }

  mounted () {
    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    } else {
      this.getDictionaryData()
    }
  }

  searchDidChange () {
    this.page = 1
    this.getDictionaryData()
  }

  onPageChange (page) {
    this.page = page
    this.getDictionaryData()
  }

  pageSize = 10

  async playSoundForPhoneme (code: string, locale: LocaleData) {
    if (!code) {
      return
    }

    // TODO: maybe create a phonics audio override in locale-map
    if (locale.underscored === 'en_NZ') {
      locale.underscored = 'en_AU'
    }
    if (locale.underscored === 'en_IE') {
      locale.underscored = 'en_GB'
    }

    const filename = 'https://files.edshed.com/audio/dictionary/' + locale + '/PHONICS/mp3/' + code.toLowerCase() + '.mp3'
    const oggFile = 'https://files.edshed.com/audio/dictionary/' + locale + '/PHONICS/ogg/' + code.toLowerCase() + '.ogg'

    await axios.get(filename, { withCredentials: false })
    await axios.get(oggFile, { withCredentials: false })

    const sound = new Howl({
      src: [oggFile, filename]
    })

    sound.play()
  }

  public formatDictionaryLanguage (loc: DictionaryLanguage) {
    return `${getLocaleEmojiFlag(loc)} ${getLanguageName(loc)}`
  }
}

